.UploadPage {
  padding: 2rem;
  text-align: center;
  height: 100%;

  h1 {
    font-size: 3rem;
  }

  .get-started {
    margin: 3rem auto;
    font-weight: 320;
    line-height: 200%;

    .select-uploaded {
      margin: 1.4rem;
      text-align: center;

      h4 {
        font-weight: 300;
        opacity: 0.7;
        letter-spacing: 0.5px;
      }

      ul.uploaded-files {
        list-style: none;
        padding: 0;

        li {
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          margin: auto;
          width: max-content;
          text-align: left;
          display: flex;
          align-items: center;
          background: #f3f3f3;
          cursor: pointer;
          transition: transform 100ms ease-out;

          &:hover {
            transform: scale(1.04);
          }

          h3 {
            font-weight: 400;
            margin-right: 2rem;
          }

          .details {
            display: flex;
            align-items: center;

            span {
              font-size: 0.8rem;
              display: inline-flex;
              align-items: center;

              &:not(:last-child) {
                margin-right: 1rem;
              }

              svg {
                margin-right: 0.3rem;
              }
            }
          }
        }
      }
    }

    button#file-upload {
      margin: 1.4rem;
      font-size: 1rem;
      font-family: inherit;
    }

    .url-input {
      width: 400px;
      margin: auto;
      text-align: left;
      margin-top: 1rem;

      label {
        font-size: 1.5rem;
        font-weight: 500;
      }

      input {
        width: 100%;
        font-size: 0.9rem;
        font-family: DM Mono, monospace;

        &::placeholder {
          font-weight: 300;
        }
      }
    }
  }
  #already-have {
    font-size: 0.9rem;
    letter-spacing: 0.3px;

    &:hover {
      color: var(--accent);
    }
  }
}
