.Home {
  display: grid;
  grid-template-columns: 40% 50%;
  justify-content: space-around;
  align-items: center;
  position: relative;
  height: 100%;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    justify-content: flex-start;
  }
}
