.Layout {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 5rem auto 3rem;
  align-items: stretch;

  header {
    padding: 1rem;
    display: flex;
    align-items: center;
    width: 100%;

    .logo {
      width: 2.5rem;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        transition: opacity 200ms ease-out;
      }

      #logo_colored {
        top: -0.2rem;
        left: 0.2rem;
        position: absolute;
        opacity: 0;
      }

      &:hover {
        #logo {
          opacity: 0;
        }

        #logo_colored {
          opacity: 1;
        }
      }
    }

    h1#main-title {
      margin-left: 1rem;
      font-size: 1.7rem;
      line-height: 90%;
      font-weight: 500;

      #catchme {
        font-weight: 800;
      }
    }

    #upload-link {
      margin-left: auto;
      margin-right: 2vw;
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem;
      border-radius: 0.3rem;
      text-align: center;
      border: 1px solid #000;
      font-weight: 500;

      &:hover {
        background: #000;
        color: #fff;
      }
    }

    #app-version {
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }
  }

  footer {
    text-align: right;
    font-size: 0.7rem;
    color: #888;
    cursor: default;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;

    #antal,
    #dezso {
      cursor: pointer;
      font-weight: 500;
      font-size: 0.8rem;
      margin: 0.25rem;
      &:hover {
        color: var(--primary);
      }
    }

    #heart {
      color: rgb(255, 0, 60);
    }
  }
}
