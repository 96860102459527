.GtfsInfo {
  align-self: center;
  margin: 2rem 0;
  position: relative;

  h1 {
    color: #333;
    padding-left: 3rem;
    font-size: 3rem;
    line-height: 1.1;
    font-weight: 500;
    position: relative;

    .route-num,
    .stop-num,
    .color-num {
      transition: opacity 500ms ease-out;
      .amount {
        font-size: 8rem;
        font-weight: 800;
        letter-spacing: -5px;
      }
    }

    .stop-num,
    .color-num {
      position: absolute;
      transform: translateY(-100%);
    }

    .color-num {
      .amount {
        color: rgb(255, 0, 81);
      }
    }
  }

  #start {
    position: absolute;
    left: 3rem;
    bottom: -8rem;
    border: 2px solid black;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    padding: 0.3rem 1.5rem;
    background: black;
    color: white;
    box-shadow: 0 2px 10px #0005;
    transition: all 200ms ease-out;

    &:active {
      box-shadow: 0 0px 5px #0003;
      transform: scale(0.95);
    }
  }
}
