.CreatePage {
  padding: 1rem;
  display: grid;
  grid-template-columns: 13rem 1fr;
  grid-template-rows: 50vh auto;
  gap: 1rem;
  height: 100%;
  justify-content: flex-start;

  #CreateMap {
    height: 50vh;
    position: sticky;
    top: 1rem;
  }

  #SelectedRoute {
    margin-top: 1rem;
    grid-column: 1/3;
    justify-self: flex-start;

    .title {
      font-weight: 500;
    }

    .stop-list {
      margin-top: 1rem;
      ul {
        padding: 0;
        max-width: 14rem;
        width: max-content;

        li {
          border: 1px solid #ccc;
          padding: 0.5rem 0.6rem;
          border-radius: 0.3rem;
          margin: 0.5rem auto;
          list-style: none;
          color: #999;
          cursor: pointer;

          &:hover {
            background: #222 !important;
            color: white !important;
          }
        }
      }
    }

    .buttons {
      margin-top: 2rem;
      width: 14rem;
      display: flex;
      justify-content: space-between;

      button {
        width: 100%;
      }
    }
  }
}
