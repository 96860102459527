@import url('https://fonts.googleapis.com/css2?family=Jost:wght@320;500;800&family=DM+Mono:wght@400&display=swap');

:root {
  --accent: #202fd4;
  --primary: #ff0051;
  --secondary: #ed9e17;
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: Jost;
  font-weight: 500;
  width: 100%;
  overflow-x: hidden;
}

h3,
h4,
h5,
h6 {
  font-weight: 500;
}

code {
  font-family: DM Mono, monospace;
  font-size: 90%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: 2px solid black;
  border-radius: 0.3rem;
  font-weight: 800;
  padding: 0.5rem 1rem;
  color: black;
  cursor: pointer;

  &.full {
    background-color: black;
    color: white;
    font-weight: 500;
  }

  &:active {
    opacity: 0.6;
  }
}

input {
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid black;
  outline: none;
}

.button-group {
  margin: 1rem;
  button {
    margin: 1rem;
  }
}

.primary {
  color: var(--primary);
}

.accent {
  color: var(--accent);
}

.secondary {
  color: var(--secondary);
}
