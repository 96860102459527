.RouteSelector {
  height: 50vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2.5rem auto;

  #RouteTypeFilter {
    font-size: 0.8rem;
    color: black;
    border: none;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    position: relative;
  }

  #RouteList {
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 0.3rem;
    padding: 0rem 1rem;
    background: #eee;
    box-shadow: inset 0 0 5px #0003;

    .RouteListItem {
      padding: 0.5rem;
      width: max-content;
      min-width: 10rem;
      font-size: 0.8rem;
      text-align: center;
      border-radius: 0.3rem;
      margin: 1rem 0;
    }
  }
}
